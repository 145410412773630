<template>
  <div class="py-10 py-md-20 px-9 px-md-18">
    <div class="d-flex flex-wrap justify-space-between align-center">
      <div class="d-flex pb-6 pb-md-12 pr-6">
        <v-btn
          class="defaultBg accentColor--text subtitle-1 px-10 mr-8"
          height="48"
          depressed
          @click="openDialogCreate"
        >
          Create Buyer
        </v-btn>
        
        <v-btn
          class="defaultBg"
          height="48"
          min-width="58"
          depressed
          @click="loadBuyers"
        >
          <v-icon color="accentColor">mdi-reload</v-icon>
        </v-btn>
      </div>
  
      <div class="input-header-container pb-6 pb-md-12">
        <v-text-field
          v-model="search"
          class="accentColor--text"
          background-color="defaultBg"
          label="Search"
          flat
          solo
          hide-details
          :readonly="loading"
          @input="serverSearch($event, 'loadBuyers')"
        >
          <template #prepend-inner>
            <v-icon
              v-if="search.length"
              color="primary"
              @click="clearSearch('loadBuyers')"
            >mdi-close</v-icon>
            <v-icon
              v-else
              color="defaultColor"
            >mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </div>
    </div>
    
    <v-data-table
      class="defaultBg"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :custom-sort="() => items"
      loader-height="2"
      mobile-breakpoint="0"
      hide-default-footer
      disable-pagination
    >
      <template #item.name="{ item }">
        <span class="text-no-wrap">{{ item.name }}</span>
      </template>
      
      <template #item.status="{ item }">
        {{ item.status ? item.status.name : '' }}
      </template>
  
      <template #item.payedSum="{ item }">
        {{ currency(item.payedSum) }}
      </template>
  
      <template #item.pendingSum="{ item }">
        {{ currency(item.pendingSum) }}
      </template>
  
      <template #item.actions="{ item }">
        <TooltipAction bottom message="Resend Invitation" v-if="item['status_id'] === 2">
          <template #icon>
            <v-icon color="accentColor" @click="resendInvite(item)">mdi-sync</v-icon>
          </template>
        </TooltipAction>
        
        <TooltipAction bottom message="Edit">
          <template #icon>
            <v-icon color="accentColor" @click="openDialogEdit(item)">mdi-pencil</v-icon>
          </template>
        </TooltipAction>
  
        <TooltipAction bottom message="Delete">
          <template #icon>
            <v-icon color="accentColor" @click="openDialogDelete(item)">mdi-delete</v-icon>
          </template>
        </TooltipAction>
      </template>
    </v-data-table>
  
    <v-pagination
      class="paginationBody text-center pt-6"
      v-if="items.length && !(dataPagination['last_page'] === 1)"
      v-model="dataPagination['current_page']"
      :length="dataPagination['last_page']"
      :page="dataPagination['current_page']"
      total-visible="8"
      :disabled="loading"
      @input="pageNext($event, 'loadBuyers')"
    />
    
    <DialogBuyer
      v-model="dialogBuyer"
      :buyer="editBuyer"
    />
    
    <DialogConfirm
      v-model="dialogConfirm"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      :loading="actionLoading"
      @successAction="confirmAction"
      @cancel="dialogConfirmWasCanceled"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import serverSearch from '@/mixins/serverSearch'
import changeOnPage from '@/mixins/changeOnPage'
import pageNext from '@/mixins/pageNext'
import currency from '@/mixins/currency'

import DialogBuyer from '@/components/dialogs/DialogBuyer'
import DialogConfirm from '@/components/dialogs/DialogConfirm'
import TooltipAction from '@/components/tooltip/TooltipAction'

export default {
  name: 'Buyers',
  components: { DialogBuyer, DialogConfirm, TooltipAction },
  mixins: [ serverSearch, changeOnPage, pageNext, currency ],
  data: () => ({
    options: {},
    headers: [
      { text: 'Name', value: 'name', sortId: 'byName' },
      { text: 'Email', value: 'email', sortId: 'byEmail' },
      { text: 'Status', value: 'status', sortId: 'byStatus' },
      { text: 'Total paid', value: 'payedSum', sortId: 'byTotalPayed' },
      { text: 'Pending sum', value: 'pendingSum', sortId: 'byPendingSum' },
      { text: 'Actions', value: 'actions', align: 'end', width: 150, sortable: false },
    ],
    dialogBuyer: false,
    editBuyer: null,
    dialogConfirm: false,
    buyerIdForResend: null,
    deleteBuyerId: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    serverSorting: null
  }),
  computed: {
    ...mapState({
      items: state => state.buyers.items,
      loading: state => state.buyers.loading,
      actionSuccess: state => state.buyers.actionSuccess,
      actionLoading: state => state.buyers.loadingAfterAction,
      dataPagination: state => state.buyers.dataPagination,
    })
  },
  methods: {
    ...mapActions({
      getBuyers: 'buyers/GET_BUYERS',
      resendInvitation: 'buyers/RESEND_INVITATION',
      deleteBuyer: 'buyers/DELETE_BUYER',
      resetState: 'buyers/RESET_STATE',
    }),
    loadBuyers() {
      const sortBy = this.serverSorting ? { ...this.serverSorting } : {}
      const params = {
        ...this.pageParams,
        search: this.search ? this.search : undefined,
        ...sortBy
      }
      this.getBuyers(params)
    },
    openDialogCreate() {
      this.editBuyer = null
      this.dialogBuyer = true
    },
    openDialogEdit(item) {
      this.editBuyer = item
      this.dialogBuyer = true
    },
    resendInvite(item) {
      this.buyerIdForResend = item.id
      this.dialogTitle = 'Resend'
      this.dialogSubTitle = `${item.email}`
      this.dialogMessage = 'Are you sure you want to resend the invitation?'
      this.dialogConfirm = true
    },
    openDialogDelete(item) {
      this.deleteBuyerId = item.id
      this.dialogTitle = 'Delete'
      this.dialogSubTitle = `${item.email}`
      this.dialogMessage = 'Are you sure you want to delete this Buyer?'
      this.dialogConfirm = true
    },
    confirmAction() {
      if (!!this.deleteBuyerId) {
        this.deleteBuyer(this.deleteBuyerId)
      } else {
        this.resendInvitation(this.buyerIdForResend)
      }
    },
    dialogConfirmWasCanceled() {
      this.buyerIdForResend = null
      this.deleteBuyerId = null
    }
  },
  watch: {
    options: {
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'desc' : 'asc' }
        } else {
          this.serverSorting = null
        }
        this.loadBuyers()
      }
    },
    actionSuccess: {
      handler(value) {
        if (value) {
          if (this.items.length === 1 && this.deleteBuyerId && this.pageParams.page !== 1) {
            this.pageParams.page = this.pageParams.page - 1
            this.buyerIdForResend = null
            this.deleteBuyerId = null
          }
          this.loadBuyers()
        }
      }
    }
  },
  beforeDestroy() {
    this.resetState()
  }
}
</script>
